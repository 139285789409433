import { Record } from 'immutable'
import { API_STATES } from 'src/utils/api'

export const User = Record({
  id: '',
  email: '',
  userName: '',
  firstName: '',
  lastName: '',
  isActive: false,
  createdAt: ''
})

export const Auth = Record({
  state: API_STATES.PRISTINE,
  user: User()
})
