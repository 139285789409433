import { Organisation, Organisations, MetaOrganisations } from 'src/features/organisations/duck/records'
import { getOrganisationsRoutine, getOrganisationDetailsRoutine } from 'src/features/organisations/duck/actions'
import { API_STATES } from 'src/utils/api'
import { transformById } from 'src/utils/redux'

export default (state = new Organisations(), action) => {
  switch (action.type) {
    case getOrganisationsRoutine.REQUEST:
    case getOrganisationDetailsRoutine.REQUEST:
      return state
        .set('state', API_STATES.IN_PROGRESS)
    case getOrganisationsRoutine.FAILURE:
    case getOrganisationDetailsRoutine.FAILURE:
      return state.set('state', API_STATES.DONE)
    case getOrganisationsRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set('organisations', transformById(action.payload.data.map(organisation => new Organisation(organisation))))
        .set('meta', new MetaOrganisations(action.payload.meta))
    case getOrganisationDetailsRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set('organisations', {
          ...state.organisations,
          [action.payload.id]: new Organisation(action.payload)
        })
    default:
      return state
  }
}
