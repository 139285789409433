import { createRoutine } from 'redux-saga-routines'
import { put, call, takeLatest, fork } from '@redux-saga/core/effects'
import { pathOr } from 'ramda'
import * as usersService from 'src/services/UsersService'

// ROUTINES

export const getOrganisationUsersRoutine = createRoutine(
  'GET_ORGANISATION_USERS'
)

// ACTIONS

function* getOrganisationUsers({ payload }) {
  yield put(getOrganisationUsersRoutine.request())
  try {
    const result = yield call(usersService.getOrganisationUsers, payload)
    yield put(getOrganisationUsersRoutine.success(pathOr([], ['data'], result)))
  } catch (e) {
    yield put(getOrganisationUsersRoutine.failure(e))
    console.error(e)
  }
}

// WATCHERS

export function* getOrganisationUsersWatcher() {
  yield takeLatest(getOrganisationUsersRoutine.TRIGGER, getOrganisationUsers)
}

// SAGAS

export const usersSagas = [
  fork(getOrganisationUsersWatcher)
]
