import { Record } from 'immutable'
import { API_STATES } from 'src/utils/api'

export const Organisation = Record({
  id: '',
  name: '',
  country: '',
  city: null,
  address: null,
  deletedAt: null,
  createdAt: '',
  updatedAt: '',
  logoUrl: null,
  currentPlanId: null,
  settings: null,
  integrations: null,
  domain: null
})

export const MetaOrganisations = Record({
  page: 1,
  take: 10,
  recordsTotal: 0,
  pagesTotal: 1
})

export const Organisations = Record({
  state: API_STATES.PRISTINE,
  organisations: {},
  meta: MetaOrganisations()
})
