import APIService from 'src/services/APIService'

const api = APIService()

/**
 * Authenticates users
 * @param {Object} payload - auth data
 * @param {string} payload.email - users email address
 * @param {string} payload.password - users password
 */
export const loginSupervisor = payload => api.post('/supervisors/auth', payload)

export const fetchAuthSupervisor = () => api.get('/supervisors/profile')

/**
 * Changes user details
 * @param {Object} payload - request data object
 * @param {string} [payload.firstName - users first name
 * @param {string} [payload.lastName] - users last name
 * @param {string} [payload.userName] - users job function
 */
export const updateAuthSupervisor = payload => api.patch('/supervisors/profile', payload)
