import { mergeDeepRight } from 'ramda'
import axios from 'axios'
import LocalStorageService from 'src/services/LocalStorageService'
import { KEYS } from 'src/utils/localStorage'

const apiUrl = process.env.GATSBY_API_URL

export const mergeConfig = config => {
  const authToken = LocalStorageService.get(KEYS.token)
  const authHeader = authToken ? { Authorization: authToken } : {}

  const defaultConfig = {
    headers: {
      Pragma: 'no-cache',
      ...authHeader
    }
  }

  return mergeDeepRight(defaultConfig)(config)
}

const get = instance => async (url, config = {}) => {
  return instance.get(url, mergeConfig(config))
}

const post = instance => async (url, data = {}, config = {}) => {
  return instance.post(url, data, mergeConfig(config))
}

const put = instance => async (url, data = {}, config = {}) => {
  return instance.put(url, data, mergeConfig(config))
}

const patch = instance => async (url, data = {}, config = {}) => {
  return instance.patch(url, data, mergeConfig(config))
}

const handleDelete = instance => async (url, config = {}) => {
  return instance.delete(url, mergeConfig(config))
}

export default (baseURL = apiUrl) => {
  const instance = axios.create({ baseURL })

  return {
    get: get(instance),
    post: post(instance),
    put: put(instance),
    patch: patch(instance),
    delete: handleDelete(instance)
  }
}
