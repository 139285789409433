import { Record } from 'immutable'
import { API_STATES } from 'src/utils/api'

export const User = Record({
  avatar: null,
  avatarUrl: null,
  id: '',
  email: '',
  firstName: '',
  lastName: '',
  nickname: '',
  phoneNumber: '',
  jobFunction: '',
  isEmailVerified: true,
  isActive: true,
  deletedAt: null,
  createdAt: '',
  updatedAt: '',
  loggedInAt: null,
  organisationId: '',
  role: '',
  settings: null,
  passwordResetTokenCreatedAt: null
})

export const MetaUsers = Record({
  page: 1,
  take: 10,
  recordsTotal: 0,
  pagesTotal: 1
})

export const Users = Record({
  state: API_STATES.PRISTINE,
  users: {},
  metaUsers: MetaUsers()
})
