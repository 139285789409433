import {
  Users,
  User,
  MetaUsers
} from 'src/features/users/duck/records'
import {
  getOrganisationUsersRoutine
} from 'src/features/users/duck/actions'
import { API_STATES } from 'src/utils/api'
import { transferCollectionToImmutableById } from 'src/utils/redux'

export default (state = new Users(), action) => {
  switch (action.type) {
    case getOrganisationUsersRoutine.REQUEST:
      return state.set('state', API_STATES.IN_PROGRESS)
    case getOrganisationUsersRoutine.FAILURE:
      return state.set('state', API_STATES.DONE)
    case getOrganisationUsersRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set(
          'users',
          transferCollectionToImmutableById(action.payload.data, User)
        )
        .set('metaUsers', new MetaUsers(action.payload.meta))
    default:
      return state
  }
}
