import { createRoutine } from 'redux-saga-routines'
import { navigate } from 'gatsby'
import { put, call, takeLatest, fork } from '@redux-saga/core/effects'
import * as authService from 'src/services/AuthService'
import { showToastRoutine } from 'src/features/toast/duck/actions'
import PATHS from 'src/utils/paths'
import LocalStorageService from 'src/services/LocalStorageService'
import { KEYS } from 'src/utils/localStorage'
import {
  getLoginErrorToastMessage
} from 'src/features/auth/duck/errors'
import { SEVERITY } from 'src/utils/toast'

// ROUTINES

export const loginSupervisorRoutine = createRoutine('LOGIN_USER')
export const logoutSupervisorRoutine = createRoutine('LOGOUT_USER')
export const fetchAuthSupervisorRoutine = createRoutine('FETCH_AUTH_USER')
export const updateAuthSupervisorRoutine = createRoutine('UPDATE_AUTH_USER')

// ACTIONS

function* loginSupervisor({ payload }) {
  yield put(loginSupervisorRoutine.request())
  try {
    const result = yield call(authService.loginSupervisor, payload)
    LocalStorageService.set(KEYS.token, result.data.token)
    yield put(loginSupervisorRoutine.success())
    navigate(PATHS.organisations)
  } catch (e) {
    yield put(loginSupervisorRoutine.failure(e))
    yield put(showToastRoutine(getLoginErrorToastMessage(e, payload.email)))
  }
}

function* logoutSupervisor() {
  yield put(logoutSupervisorRoutine.request())
  LocalStorageService.remove(KEYS.token)
  yield put(logoutSupervisorRoutine.success())
  navigate(PATHS.homepage)
}

function* fetchAuthSupervisor() {
  yield put(fetchAuthSupervisorRoutine.request())
  try {
    const result = yield call(authService.fetchAuthSupervisor)
    yield put(fetchAuthSupervisorRoutine.success(result.data))
  } catch (e) {
    yield put(fetchAuthSupervisorRoutine.failure(e))
  }
}

function* updateAuthSupervisor({ payload }) {
  yield put(updateAuthSupervisorRoutine.request())
  try {
    const result = yield call(authService.updateAuthSupervisor, payload)
    yield put(updateAuthSupervisorRoutine.success(result.data))
    yield put(
      showToastRoutine({
        key: 'toast.updateProfileSuccess',
        severity: SEVERITY.success
      })
    )
  } catch (e) {
    yield put(updateAuthSupervisorRoutine.failure(e))
    yield put(
      showToastRoutine({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    )
  }
}

// WATCHERS

export function* loginSupervisorWatcher() {
  yield takeLatest(loginSupervisorRoutine.TRIGGER, loginSupervisor)
}

export function* logoutSupervisorWatcher() {
  yield takeLatest(logoutSupervisorRoutine.TRIGGER, logoutSupervisor)
}

export function* fetchAuthSupervisorWatcher() {
  yield takeLatest(fetchAuthSupervisorRoutine.TRIGGER, fetchAuthSupervisor)
}

export function* updateAuthSupervisorWatcher() {
  yield takeLatest(updateAuthSupervisorRoutine.TRIGGER, updateAuthSupervisor)
}

// SAGAS

export const authSagas = [
  fork(loginSupervisorWatcher),
  fork(logoutSupervisorWatcher),
  fork(fetchAuthSupervisorWatcher),
  fork(updateAuthSupervisorWatcher)
]
