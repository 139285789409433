import { pathOr } from 'ramda'

export default {
  iinvalidEntity: 'entity.invalid',
  userExists: 'user.exists',
  entityNotFound: 'entity.not-found',
  userEmailNotVerified: 'user.email.unverified',
  notEnoughPoints: 'appreciation.not-enough-points',
  lowerThanMinimumPoints: 'appreciation.give-min-points',
  accountInactive: 'user.inactive'
}

export const getErrorName = error =>
  pathOr('', ['response', 'data', 'name'], error)
