const localStorageSchema = {
  localStorage: {
    getItem: () => {},
    setItem: () => {},
    removeItem: () => {},
    clear: () => {}
  }
}
// Workaround for build error https://github.com/gatsbyjs/gatsby/issues/309#issuecomment-347595352
const { localStorage } =
  typeof window !== 'undefined' ? window : localStorageSchema

export const storageService = {
  get: key => localStorage.getItem(key),
  set: (key, value) => localStorage.setItem(key, value),
  remove: key => localStorage.removeItem(key),
  clear: () => localStorage.clear()
}

export default storageService
