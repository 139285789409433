import errors, { getErrorName } from 'src/utils/errors'
import { SEVERITY } from 'src/utils/toast'

export const getLoginErrorToastMessage = (error, email) => {
  let toastPayload = { severity: SEVERITY.error }
  switch (getErrorName(error)) {
    case errors.entityNotFound:
      toastPayload = { ...toastPayload, key: 'toast.emailNotFound' }
      break
    default:
      toastPayload = { ...toastPayload, key: 'toast.loginFailed' }
      break
  }

  return toastPayload
}
