import { Auth, User } from 'src/features/auth/duck/records'
import {
  loginSupervisorRoutine,
  logoutSupervisorRoutine,
  fetchAuthSupervisorRoutine,
  updateAuthSupervisorRoutine
} from 'src/features/auth/duck/actions'
import { API_STATES } from 'src/utils/api'

export default (state = new Auth(), action) => {
  switch (action.type) {
    case fetchAuthSupervisorRoutine.REQUEST:
    case loginSupervisorRoutine.REQUEST:
    case updateAuthSupervisorRoutine.REQUEST:
      return state
        .set('state', API_STATES.IN_PROGRESS)
    case loginSupervisorRoutine.FAILURE:
    case loginSupervisorRoutine.SUCCESS:
    case fetchAuthSupervisorRoutine.FAILURE:
    case updateAuthSupervisorRoutine.FAILURE:
      return state.set('state', API_STATES.DONE)
    case fetchAuthSupervisorRoutine.SUCCESS:
    case updateAuthSupervisorRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set('user', new User(action.payload))
    case logoutSupervisorRoutine.SUCCESS:
      return new Auth()
    default:
      return state
  }
}
