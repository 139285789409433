import { combineReducers } from 'redux'
import { routinePromiseWatcherSaga } from 'redux-saga-routines'
import { all, fork } from '@redux-saga/core/effects'

// initialStates
import { Auth } from 'src/features/auth/duck/records'
import { Toast } from 'src/features/toast/duck/records'
import { Users } from 'src/features/users/duck/records'
import { Organisations } from 'src/features/organisations/duck/records'

// reducers
import toastReducer from 'src/features/toast/duck/reducers'
import authReducer from 'src/features/auth/duck/reducers'
import organisationsReducer from 'src/features/organisations/duck/reducers'
import usersReducer from 'src/features/users/duck/reducers'

// sagas
import { toastSagas } from 'src/features/toast/duck/actions'
import { authSagas, logoutSupervisorRoutine } from 'src/features/auth/duck/actions'
import { organisationsSagas } from 'src/features/organisations/duck/actions'
import { usersSagas } from 'src/features/users/duck/actions'

// reducer
const appReducer = combineReducers({
  toast: toastReducer,
  auth: authReducer,
  organisations: organisationsReducer,
  users: usersReducer
})

const initialReducer = {
  auth: new Auth(),
  toast: new Toast(),
  users: new Users(),
  organisations: new Organisations(),
}

const rootReducer = (state, action) => {
  if (action.type === logoutSupervisorRoutine.SUCCESS) {
    state = initialReducer
  }

  return appReducer(state, action)
}

export default rootReducer

export function* rootSaga() {
  yield all([
    ...toastSagas,
    ...authSagas,
    ...organisationsSagas,
    ...usersSagas,
    fork(routinePromiseWatcherSaga)
  ])
}
