import { createRoutine } from 'redux-saga-routines'
import { put, call, takeLatest, fork } from '@redux-saga/core/effects'
import * as organisationsService from 'src/services/OrganisationsService'

// ROUTINES

export const getOrganisationsRoutine = createRoutine('GET_ORGANISATIONS')
export const getOrganisationDetailsRoutine = createRoutine(
  'GET_ORGANISATION_DETAILS'
)
// ACTIONS

function* getOrganisations({ payload }) {
  yield put(getOrganisationsRoutine.request())
  try {
    const result = yield call(organisationsService.getOrganisations, payload)
    yield put(getOrganisationsRoutine.success(result.data))
  } catch (e) {
    yield put(getOrganisationsRoutine.failure(e))
  }
}

function* getOrganisationDetails({ payload }) {
  yield put(getOrganisationDetailsRoutine.request())
  try {
    const result = yield call(organisationsService.getOrganisationDetails, payload)
    yield put(getOrganisationDetailsRoutine.success(result.data))
  } catch (e) {
    yield put(getOrganisationDetailsRoutine.failure(e))
    console.error(e)
  }
}

// WATCHERS

export function* getOrganisationsWatcher() {
  yield takeLatest(getOrganisationsRoutine.TRIGGER, getOrganisations)
}

export function* getOrganisationDetailsWatcher() {
  yield takeLatest(
    getOrganisationDetailsRoutine.TRIGGER,
    getOrganisationDetails
  )
}

// SAGAS

export const organisationsSagas = [
  fork(getOrganisationsWatcher),
  fork(getOrganisationDetailsWatcher)
]
